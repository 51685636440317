// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   serverUrl: 'http://localhost:1337/api',
//   appUrl: 'https://localhost:8100',
//   appImageUrl: 'https://www.tryion.shop/assets/imgs/icon.png',
//   appId: 'myAppId',
//   fbId: 'YOUR_FACEBOOK_APP_ID',
//   stripePublicKey: 'YOUR_STRIPE_PUBLIC_KEY',
//   androidHeaderColor: '#222428',
//   defaultLang: 'es',
//   googleClientId: 'xxxxxxxxxxxxxxxxxxxxxxxxxx.apps.googleusercontent.com',
//   currency: {
//     code: 'GMD',
//     display: 'symbol',
//     digitsInfo: '1.2-2',
//   },
//   oneSignal: {
//     appId: 'YOUR_ONESIGNAL_APP_ID',
//   },
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


export const environment = {
  production: true,
  serverUrl: 'https://myapi.biiteq.com/api',
  appUrl: 'https://app.biiteq.com',
  appImageUrl: 'https://app.biiteq.com/assets/imgs/icon.png',
  appId: 'YB88NpmVUV',
  fbId: '230008602764540',
  stripePublicKey: 'pk_live_51MkplUGKn1YAEzmYKhtV2pASqfFGGUVhk53VfUJVm02jgSM0rFj9Zi7crsRGe8ecwnDvBQBOUv54SDftCTmENS1w00841ou3ru',
  androidHeaderColor: '#222428',
  defaultLang: 'en',
  googleClientId: '713120115562-oesuf3eat67ktkp2s7c9a3o2r56nhin9.apps.googleusercontent.com',
  currency: {
    code: 'GMD',
    display: 'symbol',
    digitsInfo: '1.2-2',
  },
  oneSignal: {
    appId: 'f10b9c4a-5b14-4b68-8360-3d881c92c0e1',
  },
};